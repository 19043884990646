<template>
  <CardCarouselSection
    :id="item.fields.id"
    :color-mode="item.fields.theme"
    :items="carouselItems"
    :negative-top="item.fields.overlap ?? false"
  />
</template>

<script setup lang="ts">
import { CardCarouselSection } from '@hypercodestudio/basler-components';
import type { CarouselItem } from '@hypercodestudio/basler-components/dist/components/content-modules/sections/CardCarouselSection.vue';
import type { ICarouselSection } from '~/lib/ContentfulService';
import { generateImageObject } from '~/utils/generateImageObject';
import { buildLinkInterface } from '~/utils/buildLinkInterface';
import { isEntryResolveError } from '~/utils/guards/isEntryResolveError';

interface Props {
  item: ICarouselSection;
}

const props = defineProps<Props>();

const carouselItems = computed(
  (): CarouselItem[] =>
    props.item.fields.carouselItems
      ?.filter((entry) => !isEntryResolveError(entry))
      ?.map<CarouselItem>((carouselItem) => {
        const image = carouselItem.fields.media;

        const imageMedia = image && generateImageObject(image);

        return {
          title:
            carouselItem.fields.navigationTitle ?? carouselItem.fields.headline,
          content: {
            header: imageMedia,
            content: carouselItem.fields.description,
            headline: carouselItem.fields.headline,
            link: buildLinkInterface(carouselItem.fields.link)
          }
        };
      }) ?? []
);
</script>
